<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}" v-if="division">
    <BidStream></BidStream>

    <!-- BREADCRUMBS -->
    <v-row dense v-if="tournament.infoAlerts && tournament.infoAlerts.length > 0">
      <v-col cols="12">
          <v-alert
            v-for="alert in tournament.infoAlerts"
            :key="alert.id"
            :type="alert.type"
            :value="true"
            :dismissible="alert.dismissable"
            prominent
          >
            {{alert.text}}
          </v-alert>
      </v-col>
    </v-row>
    <v-row dense class="not-printable">
      <v-col cols="12">
        <vbl-breadcrumbs :crumbs="breadcrumbs"></vbl-breadcrumbs>
      </v-col>
    </v-row>
    <v-row dense v-if="view.admin && tournament.readyToComplete && !tournament.locked">
      <v-col cols="12">
        <v-alert
          type="warning"
          class="black--text"
          :value="view.admin && tournament.readyToComplete && !tournament.locked"
          transition="scale-transition"
        >
          All your divisions are complete
          <v-btn
            color="black white--text"
            @click.stop="lockTournament"
            :loading="locking"
          >Lock The Tournament</v-btn>
        </v-alert>
      </v-col>
    </v-row>
    <!-- MAIN CONTENT -->
    <v-row dense>
      <v-col cols="12">
        <v-card height="100%">
          <v-toolbar tabs color="color1 color1Text--text">
            <v-toolbar-title>{{division.name}} {{division.isFull ? ' - Full' : ''}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <quick-add-dialog
              v-if="view.admin && !locked"
              :tournament="tournament"
              :division="division"
              fab
            ></quick-add-dialog>
            <lock-toggle v-if="view.admin && division.complete" :warn="true" @unlock-click="onUnlockClick" :title="`Unlock ${division.name}?`">
              <div class="subheading">Results have are been submitted.</div>
              If you change any finishes now you must re-finish the division to update and submit the results.
            </lock-toggle>
          </v-toolbar>
          <v-tabs
            background-color="color2"
            slider-color="color3"
            active-class="color3 color3Text--text"
            slider-size="3"
            show-arrows
            :dark="color2IsDark"
            center-active
            v-model="activeTab"
          >
            <v-tab
              v-for="(tab) in divisionTabs"
              :key="tab.to"
              :to="tab.to"
              class="color2Text--text"
              active-class="color3 color3Text--text"
            >
              <v-icon v-if="tab.icon" :color="activeTab === tab.to ? 'color3Text' : 'color2Text'" class="pr-1" small>{{tab.icon}}</v-icon>
              {{tab.text}}
            </v-tab>
          </v-tabs>
          <v-alert
            :value="looksComplete"
            transition="scale-transition"
            type="warning"
            prominent tile
            class="mb-0"
          >
              <v-row align="center">
                <v-col class="grow">
                  <strong class="black--text">{{division.name}} appears to be done.</strong>
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    @click.stop="dialog = true"
                  >Finish</v-btn>
                </v-col>
              </v-row>
          </v-alert>
          <v-tabs-items v-model="activeTab" touchless>
            <v-tab-item
              v-for="(tab) in divisionTabs"
              :key="tab.to"
              :value="tab.to"
            >
              <router-view
                v-if="activeTab === tab.to"
                :key="`${divisionId}-${looksComplete}`"
                :courting="courting"
                @match-click="onMatchClick"
              >
              </router-view>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="view.admin" class="xsmall">
        Hydrated = {{division.hydrated}}
      </v-col>
    </v-row>

    <v-dialog
      v-if="view.admin"
      v-model="dialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>{{division.name}} Finished?</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="color2 color2Text--text" small fab @click.stop="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="subheading">
          {{division.name}} appears to be done. Would you like to lock all the rounds and publish the results?
          <div class="mt-4">(Once locked, you will not be able to make any changes unless you unlock the division)</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            color="success white--text"
            :loading="loading"
            @click.stop="finishMe"
          >Yes</v-btn>
          <v-btn color="error white--text" @click.stop="dialog = false" text>I'll do it later</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <court-wizard
      data-html2canvas-ignore
      v-if="view.admin && bracket && !locked && !(this.round && this.round.locked)"
      ref="courtWiz"
      class="not-printable"
      :bracket="bracket"
      @open-change="onOpenChange"
    ></court-wizard>

  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import * as mutations from '@/store/MutationTypes'
import { mapGetters } from 'vuex'
const QuickAddDialog = () => import('@/components/Tournament/Registration/QuickAddDialog.vue')
const LockToggle = () => import('@/components/Utils/LockToggle.vue')
const CourtWizard = () => import('@/components/Tournament/Brackets/CourtWizard2')

export default {
  mixins: [RouteMixin],
  data () {
    return {
      dialog: false,
      loading: false,
      activeTab: null,
      courting: false,
      locking: false
    }
  },
  computed: {
    ...mapGetters([
      'admin',
      'view',
      'tournament',
      'getDivision',
      'color2IsDark',
      'getDivisionJProp'
    ]),
    breadcrumbs () {
      const crumbs = [
        {
          text: `${this.tournament.eventType} Home`,
          to: { name: 'tournament-brochure' },
          exact: true,
          disabled: false
        },
        {
          text: this.division.name,
          to: { name: 'division-home' },
          exact: true,
          disabled: false
        }
      ]
      if (this.$route.name !== 'division-home' && this.$route.meta.displayName) {
        crumbs.push(
          {
            text: this.$route.meta.displayName,
            disabled: true
          }
        )
      }
      crumbs[crumbs.length - 1].disabled = true
      return crumbs
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    round () {
      if (!this.division || !this.dayId) return null
      return this.division.days.find(f => f.id === this.dayId)
    },
    bracket () {
      if (!this.round) return null
      return this.round.brackets[0]
    },
    isTournament () {
      return this.tournament.isTournament && this.division.isTournament
    },
    isLeague () {
      return this.tournament.isLeague
    },
    isCoaches () {
      return this.division && this.division.isCoaches
    },
    isCS () {
      return this.division && this.division.isCS
    },
    divisionTabs () {
      const tabs = [{
        text: 'Home',
        to: `/tournament/${this.tournamentId}/division/${this.divisionId}/Home`
      }]

      this.view.admin && tabs.push(
        {
          text: 'Settings',
          to: `/tournament/${this.tournamentId}/division/${this.divisionId}/settings`
        })
      this.view.admin && this.isTournament && !this.isCS && tabs.push(
        {
          text: 'Round Manager',
          to: `/tournament/${this.tournamentId}/division/${this.divisionId}/rounds`
        })
      this.view.admin && this.isLeague && !this.isCS && tabs.push(
        {
          text: 'Schedule Manager',
          to: `/league/${this.tournamentId}/division/${this.divisionId}/schedule`
        })

      tabs.push(
        {
          text: `${this.division.acceptanceOnly ? 'Accepted ' : ''}${this.division.teamLabel}`,
          to: `/tournament/${this.tournamentId}/division/${this.divisionId}/teams`,
          count: this.division.teamCount
        }
      )
      if (this.division.waitlistTeams.length && !this.isCS && (this.view.admin || this.division.showTeams) && !(this.view.public && this.division.hideWaitlist)) {
        tabs.push(
          {
            text: this.division.acceptanceOnly ? 'Pending Teams' : 'Waitlist',
            to: `/tournament/${this.tournamentId}/division/${this.divisionId}/waitlist`,
            count: this.division.waitlistTeams.length
          }
        )
      }
      if ((this.isTournament || this.isLeague) && !this.isCS) {
        const stand = this.division.standings
        stand && stand.filter(f => (this.view.admin) || (this.view.public && f.published)).forEach((day, i) => {
          tabs.push({
            text: day.title,
            to: `/tournament/${this.tournamentId}/division/${this.divisionId}/standings/${i}`,
            icon: day.locked ? 'fas fa-lock' : null
          })
        })
        if (this.isTournament) {
          this.division.days.filter(f => (this.view.admin) || (this.view.public && f.published)).forEach(day => {
            if (day.poolPlay) {
              const poolId = day.flight && day.flight.pools && day.flight.pools.length === 1 && day.flight.pools[0].id
              const addOn = poolId ? `/${poolId}` : ''
              tabs.push({
                text: day.name,
                to: `/tournament/${this.tournamentId}/division/${this.divisionId}/round/${day.id}/pools${addOn}`,
                count: day.pools.length,
                icon: day.locked ? 'fas fa-lock' : null
              })
            } else {
              tabs.push({
                text: day.name,
                to: `/tournament/${this.tournamentId}/division/${this.divisionId}/round/${day.id}/brackets`,
                icon: day.locked ? 'fas fa-lock' : null
              })
              if (day.bracket && day.bracket.losers.length) {
                tabs.push({
                  text: day.bracket.losersName,
                  to: `/tournament/${this.tournamentId}/division/${this.divisionId}/round/${day.id}/brackets/contenders`,
                  icon: day.locked ? 'fas fa-lock' : null
                })
              }
            }
          })
        }
        if (this.isLeague) {
          tabs.push({
            text: 'Regular Season',
            to: `/league/${this.tournamentId}/division/${this.divisionId}/regular_season`
          })
          this.division.days.filter(f => (this.view.admin) || (this.view.public && f.published)).forEach(day => {
            const props = this.tournament.jProps && this.tournament.jProps.rounds && this.tournament.jProps.rounds[day.id]
            if (props && props.tournamentStyle) {
              const poolId = day.flight && day.flight.pools && day.flight.pools.length === 1 && day.flight.pools[0].id
              const addOn = poolId ? `/${poolId}` : ''
              tabs.push({
                text: day.name,
                to: `/league/${this.tournamentId}/division/${this.divisionId}/round/${day.id}/pools${addOn}`,
                count: day.pools.length,
                icon: day.locked ? 'fas fa-lock' : null
              })
            }
            if (!day.poolPlay) {
              tabs.push({
                text: day.name,
                to: `/league/${this.tournamentId}/division/${this.divisionId}/round/${day.id}/brackets`,
                icon: day.locked ? 'fas fa-lock' : null
              })
            }
          })
        }
      }
      return tabs
    },
    looksComplete () {
      return this.view.admin && this.division.looksComplete
    },
    locked () {
      return this.tournament.locked || this.division.complete
    },
    isLive () {
      return this.$refs && this.$refs.live && this.$refs.live.dialog
    },
    silentLoad () {
      return this.division && this.division.hydrated
    }
  },
  methods: {
    hydrate () {
      this.division.hydrate(this.$VBL)
    },
    finishMe () {
      this.loading = true
      this.$VBL.division.finish(this.divisionId)
        .then(r => { this.dialog = false })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => { this.loading = false })
    },
    onUnlockClick () {
      this.loading = true
      const dto = { complete: false }
      this.division.patch(dto)
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onOpenChange (v) {
      console.log(`open changed to ${v}`)
      this.courting = v
    },
    onMatchClick (match) {
      if (this.courting) this.$refs && this.$refs.courtWiz && this.$refs.courtWiz.addMatch(match)
    },
    lockTournament () {
      const cb = () => {
        this.locking = false
      }

      this.locking = true
      this.tournament.lockMe(cb)
    }
  },
  components: {
    QuickAddDialog,
    LockToggle,
    CourtWizard
  },
  watch: {
    'division.loading': function (val) {
      val && !this.silentLoad ? this.$store.commit(mutations.LOADING, 'division.loading')
        : this.$store.commit(mutations.LOADING_COMPLETE, 'division.loading')
    },
    looksComplete: function (val) {
      if (this.view.admin) this.dialog = val
    },
    division: function (val) {
      if (val.canceled) {
        console.log('canceled')
      }
    }
  },
  mounted () {
    if (this.division.canceled && this.view.public) {
      this.$router.push(`/tournament/${this.tournamentId}`)
    }
    this.dialog = this.looksComplete && this.view.admin
    this.activeTeams = `/tournament/${this.tournamentId}/division/${this.divisionId}`
  },
  beforeRouteUpdate (to, from, next) {
    // console.log('route change')
    next()
  }
}
</script>

<style>

</style>
