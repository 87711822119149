export default {
  computed: {
    dayId () {
      return +this.$route.params.dayId
    },
    divisionId () {
      return +this.$route.params.divisionId
    },
    poolId () {
      return +this.$route.params.poolId
    },
    roundId () {
      return +this.$route.params.dayId
    },
    teamId () {
      return +this.$route.params.teamId
    },
    tournamentId () {
      return +this.$route.params.tournamentId
    },
    standingsI () {
      return +this.$route.params.standingsI
    },
    liveProps () {
      return this.$route.query && this.$route.query.liveN && {
        n: +this.$route.query.liveN,
        id: +(this.$route.query.liveId | 0)
      }
    }
  }
}
